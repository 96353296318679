import React, { Component } from "react";

import { Link } from "gatsby"

import Layout from "../components/layou_en"
import Banner from "../components/others/banner"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import div from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { StaticQuery, graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'

const objectGraphql = graphql`
{
  strapiQualityControl(locale: {eq: "en"}) {
    Banner {
      fondo {
        url
      }
      url {
        enlace
        titulo
        id
      }
      titulo
      id
    }
    pasos {
      descripcion
      id
      izq_o_deq
      titulo
      img {
        url
      }
    }
  }
  allStrapiHome(filter: {locale: {eq: "en"}}) {
    edges {
      node {
        id
        Cantidad_noticias
        productos_y_servicios {
          descripcion
          id
          imagen {
            url
          }
          titulo
        }
      }
    }
  }
}

`


class QualityControl extends Component {

  render() {

var settings = {
      dots: false,
      arrows:false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      // autoplay: true,
      speed: 100,
      autoplaySpeed: 6500,
      pauseOnHover: true , 
      // cssEase: "linear",


      responsive: [

      {
      breakpoint: 600,
      settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 2
      }
      },
      {
      breakpoint: 480,
      settings: {
      slidesToShow: 1,
      slidesToScroll: 1
      }
      }
      ],
    };
    return (
      <StaticQuery
        query={objectGraphql}
        render={data => (
          <React.Fragment>
            <Layout props={this.props.props}>
              <Banner banner={data.strapiQualityControl.Banner} />
              <section id="productos-interna">
                <div className="container">
                  {
                    data.strapiQualityControl.pasos.map((item, index) => {
                      return (
                        <React.Fragment>
                          {console.log(item)}
                          {!item.izq_o_deq &&
                            <div key={index} className="row gestion-int">
                              <div data-sal="zoom-in"

                                data-sal-duration="800" className="col-sm-5 col-md-3 col-lg-4 offset-lg-2 text-center wow zoomIn" >
                                <p><img src={item.img.url} className="img-fluid" alt={item.img.url} /></p>
                              </div>
                              <div className="col-sm-7 col-md-9 col-lg-4">
                                <h4>{item.titulo}</h4>

                                <ReactMarkdown>{item.descripcion}</ReactMarkdown>
                              </div>
                            </div>
                          }
                          {item.izq_o_deq &&
                            <div key={index} className="row gestion-int">
                            <div data-sal="zoom-in"

                              data-sal-duration="800"
                              className="col-sm-5 col-md-3 col-lg-4 text-center f-none  wow zoomIn" >
                              <p><img src={item.img.url} className="img-fluid" alt={item.img.url} /></p>
                            </div>
                            <div className="col-sm-7 col-md-9 col-lg-4 offset-lg-2 text-right">
                              <h4>{item.titulo}</h4>

                              <ReactMarkdown>{item.descripcion}</ReactMarkdown> </div>
                            <div data-sal="zoom-in"

                              data-sal-duration="800"
                              className="col-sm-5 col-md-3 col-lg-4 text-center f-none-c  wow zoomIn" >
                              <p><img src={item.img.url} className="img-fluid" alt={item.img.url} /></p>
                            </div>
                          </div>
                          }
                        </React.Fragment>)
                    })
                  }

                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 titulo-center-b text-center wow " animateIn="fadeInUp">
                      <h2>
                        Products and services
                      </h2>
                    </div>
                  </div>
                   <div className="row">
                    <div className="col-md-12 wow fadeInUp">
                      <div id="productos" animateIn="fadeInUp">
                  
                  

                        <Slider  {...settings}
                        >
                        {

                        (data.allStrapiHome.edges[0].node.productos_y_servicios).concat(data.allStrapiHome.edges[0].node.productos_y_servicios).map((item) => {
                        return (
                          <div style={{ margin: '20px' }}  key={item.id} data-value={item.id} className="item text-center item__slick">

                                <Link to="/en/products">
    <img  src={item.imagen[0].url} alt={item.imagen[0].url} className="img-fluid" /></Link>

                              <h5 style={{ marginTop: '25px' }}>
                                                              <Link to="/en/products">
{item.titulo}</Link>
                              </h5>
                              <p></p>
                              <p style={{width:'80%' ,margin:"auto",lineHeight: 1.6}}>{item.descripcion}</p>
                              <p></p>

                          </div>
                           )
                                          })
                                       }
                      

                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          </ React.Fragment>
        )}
      />
    )
  }
}

export default QualityControl
